import React from 'react';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import { SubscriptionType } from '../../types/subscriptions.types';
import { CUSTOM_PLAN_NAME } from '../../const/subscriptions.const';
import * as styles from './SubscriptionPlanOptions.module.scss';
import OptionItem from './OptionItem';

type SubscriptionPlanOptionsProps = {
  plan: SubscriptionType;
};
const SubscriptionPlanOptions = ({
  plan,
}: SubscriptionPlanOptionsProps): React.ReactElement => {
  const { t } = useTranslation();
  const isCustom = plan.name === CUSTOM_PLAN_NAME;
  const teamMembers = plan.team_members
    ? plan.team_members - 1
    : plan.team_members;

  const apiRateLimitText = isCustom ? <>RPM, RPH <Trans>Custom</Trans></> : <>RPM {plan.api_rate_limit}, RPH {plan.api_rate_limit_hour}</>;

  return (
    <ul className={styles.wrapper}>
      <OptionItem
        text={
          isCustom ? (
            <Trans>Custom profiles</Trans>
          ) : (
            t('price_table.profiles', { count: plan.profiles })
          )
        }
      />
      <OptionItem
        text={
          isCustom ? (
            <Trans>Custom tags</Trans>
          ) : (
            t('price_table.tags', { count: plan.tags })
          )
        }
      />
      <OptionItem text={<Trans>Unlimited devices</Trans>} />
      <OptionItem text={<Trans>Proxy manager</Trans>} />
      {plan.templates > 0 ? (
        <OptionItem
          text={
            isCustom ? (
              <Trans>Custom templates</Trans>
            ) : (
              t('price_table.profile_templates', {
                count: plan.templates,
              })
            )
          }
        />
      ) : (
        <OptionItem disabled text={<Trans>Profile templates</Trans>} />
      )}
      <OptionItem
        disabled={!plan.api_enabled}
        text={<><Trans>Access to the API</Trans> ({apiRateLimitText})</>}
      />
      <OptionItem
        disabled={!plan.transfer}
        text={<Trans>Profile transfer</Trans>}
      />
      {teamMembers > 1 ? (
        <OptionItem
          text={
            isCustom ? (
              <Trans>Custom team</Trans>
            ) : (
              t('price_table.team_members', {
                count: teamMembers,
              })
            )
          }
        />
      ) : (
        <OptionItem disabled text={<Trans>Team members</Trans>} />
      )}
    </ul>
  );
};

export default SubscriptionPlanOptions;
