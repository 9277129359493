import React from 'react';
import { graphql } from 'gatsby';
import { IS_NY_SALE_TIME } from '../const/app.const';
import Layout from '../layouts';
import Pricing from '../components/Pricing/Pricing';
import ActivateWithButton from '../components/ActivateWithButton/ActivateWithButton';
import PaymentMethods from '../components/PaymentMethods/PaymentMethods';
import FaqPricing from '../components/FaqPricing/FqaPricing';
import DownBlock from '../components/DownBlock/DownBlock';
import PricingSaleTicker from '../components/Pricing/PricingSaleTicker';

const PricingPage = (): React.ReactElement => (
  <Layout>
    {IS_NY_SALE_TIME && <PricingSaleTicker />}
    <Pricing isNYSaleTime={IS_NY_SALE_TIME} />
    <ActivateWithButton />
    <PaymentMethods />
    <FaqPricing />
    <DownBlock />
  </Layout>
);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["translation", "pricingPage"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default PricingPage;
