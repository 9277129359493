import React from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';
import Button from '../Button/Button';
import { ButtonColor, ButtonSize } from '../../const/button.conts';
import { SIGN_UP_ROUTE } from '../../const/routes.const';
import ContentWrap from '../ContentWrap/ContentWrap';
import * as styles from './ActivateWithButton.module.scss';

const ActivateWithButton = (): React.ReactElement => (
  <div className={styles.wrapper}>
    <ContentWrap>
      <div className={styles.title}>
        <Trans>Top Up and activate a subscription after registration</Trans>
      </div>
      <Button
        color={ButtonColor.primary}
        label={<Trans>Create Octo Browser account</Trans>}
        link={SIGN_UP_ROUTE}
        size={ButtonSize.middle}
      />
    </ContentWrap>
  </div>
);

export default ActivateWithButton;
