// extracted by mini-css-extract-plugin
export var antBtnLg = "PaymentMethods-module--ant-btn-lg--Xw+vk";
export var antBtnPrimary = "PaymentMethods-module--ant-btn-primary--E+vGr";
export var antFormItem = "PaymentMethods-module--ant-form-item--z7WqS";
export var antFormItemLabel = "PaymentMethods-module--ant-form-item-label--a2d-a";
export var antInput = "PaymentMethods-module--ant-input--UKoQt";
export var antMessageNotice = "PaymentMethods-module--ant-message-notice--0VVl6";
export var antMessageNoticeContent = "PaymentMethods-module--ant-message-notice-content--uBqZO";
export var errorMessage = "PaymentMethods-module--errorMessage--OGCpP";
export var method = "PaymentMethods-module--method--YMHPW";
export var methods = "PaymentMethods-module--methods--m5tP+";
export var title = "PaymentMethods-module--title--16JSe";
export var wrapper = "PaymentMethods-module--wrapper--xytyE";