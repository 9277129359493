// extracted by mini-css-extract-plugin
export var antBtnLg = "SubscriptionPlan-module--ant-btn-lg--hozTT";
export var antBtnPrimary = "SubscriptionPlan-module--ant-btn-primary--ezz+9";
export var antFormItem = "SubscriptionPlan-module--ant-form-item--l9s4E";
export var antFormItemLabel = "SubscriptionPlan-module--ant-form-item-label--jvb6m";
export var antInput = "SubscriptionPlan-module--ant-input--KFO0x";
export var antMessageNotice = "SubscriptionPlan-module--ant-message-notice--5fwVY";
export var antMessageNoticeContent = "SubscriptionPlan-module--ant-message-notice-content--Du1A9";
export var discount = "SubscriptionPlan-module--discount--zoYjq";
export var errorMessage = "SubscriptionPlan-module--errorMessage--Yuk7L";
export var header = "SubscriptionPlan-module--header--dw3cp";
export var popular = "SubscriptionPlan-module--popular--JDUE6";
export var popularMark = "SubscriptionPlan-module--popularMark--agFvU";
export var title = "SubscriptionPlan-module--title--pPyu0";
export var titleWrapper = "SubscriptionPlan-module--titleWrapper--0O9n2";
export var wrapper = "SubscriptionPlan-module--wrapper--vDOdE";