import { SubscriptionType } from '../types/subscriptions.types';

export const SUBSCRIPTION_PLANS_TERMS = [30, 90, 180, 360];
export const DAYS_IN_MONTH = 30;
export const CUSTOM_PLAN_NAME = 'custom';

export const SUBSCRIPTION_PLANS: Array<SubscriptionType> = [
  {
    id: 2,
    custom: false,
    name: 'starter',
    api_rate_limit: 0,
    api_rate_limit_hour: 0,
    team_members: 1,
    tags: 3,
    profiles: 10,
    proxies: 1,
    images: 3,
    templates: 0,
    duration: 30,
    transfer: false,
    price: 29,
    api_enabled: false,
    export_price: 50,
    is_trial: false,
    bonuses: {
      '30': { full_price: 29, bonus_price: null, bonus: null },
      '60': { full_price: 58, bonus_price: null, bonus: null },
      '90': {
        full_price: 87,
        bonus_price: 79,
        bonus: {
          type: 'DISCOUNT_3_MONTH',
          config: { name: 'DISCOUNT_3_MONTH', value: 10, days: 90 },
          short_description: '-10%',
          long_description: '10% discount',
        },
      },
      '180': {
        full_price: 174,
        bonus_price: 140,
        bonus: {
          type: 'DISCOUNT_6_MONTH',
          config: { name: 'DISCOUNT_6_MONTH', value: 20, days: 180 },
          short_description: '-20%',
          long_description: '20% discount',
        },
      },
      '360': {
        full_price: 348,
        bonus_price: 244,
        bonus: {
          type: 'DISCOUNT_12_MONTH',
          config: { name: 'DISCOUNT_12_MONTH', value: 30, days: 360 },
          short_description: '-30%',
          long_description: '30% discount',
        },
      },
    },
  },
  {
    id: 3,
    custom: false,
    name: 'base',
    api_rate_limit: 50,
    api_rate_limit_hour: 500,
    team_members: 1,
    tags: 10,
    profiles: 100,
    proxies: 100,
    images: 5,
    templates: 2,
    duration: 30,
    transfer: true,
    price: 79,
    api_enabled: true,
    export_price: 50,
    is_trial: false,
    bonuses: {
      '30': { full_price: 79, bonus_price: null, bonus: null },
      '60': { full_price: 158, bonus_price: null, bonus: null },
      '90': {
        full_price: 237,
        bonus_price: 214,
        bonus: {
          type: 'DISCOUNT_3_MONTH',
          config: { name: 'DISCOUNT_3_MONTH', value: 10, days: 90 },
          short_description: '-10%',
          long_description: '10% discount',
        },
      },
      '180': {
        full_price: 474,
        bonus_price: 380,
        bonus: {
          type: 'DISCOUNT_6_MONTH',
          config: { name: 'DISCOUNT_6_MONTH', value: 20, days: 180 },
          short_description: '-20%',
          long_description: '20% discount',
        },
      },
      '360': {
        full_price: 948,
        bonus_price: 664,
        bonus: {
          type: 'DISCOUNT_12_MONTH',
          config: { name: 'DISCOUNT_12_MONTH', value: 30, days: 360 },
          short_description: '-30%',
          long_description: '30% discount',
        },
      },
    },
  },
  {
    id: 4,
    custom: false,
    name: 'team',
    api_rate_limit: 100,
    api_rate_limit_hour: 1500,
    team_members: 4,
    tags: 30,
    profiles: 350,
    proxies: 350,
    images: 10,
    templates: 5,
    duration: 30,
    transfer: true,
    price: 169,
    api_enabled: true,
    export_price: 50,
    is_trial: false,
    bonuses: {
      '30': { full_price: 169, bonus_price: null, bonus: null },
      '60': { full_price: 338, bonus_price: null, bonus: null },
      '90': {
        full_price: 507,
        bonus_price: 457,
        bonus: {
          type: 'DISCOUNT_3_MONTH',
          config: { name: 'DISCOUNT_3_MONTH', value: 10, days: 90 },
          short_description: '-10%',
          long_description: '10% discount',
        },
      },
      '180': {
        full_price: 1014,
        bonus_price: 812,
        bonus: {
          type: 'DISCOUNT_6_MONTH',
          config: { name: 'DISCOUNT_6_MONTH', value: 20, days: 180 },
          short_description: '-20%',
          long_description: '20% discount',
        },
      },
      '360': {
        full_price: 2028,
        bonus_price: 1420,
        bonus: {
          type: 'DISCOUNT_12_MONTH',
          config: { name: 'DISCOUNT_12_MONTH', value: 30, days: 360 },
          short_description: '-30%',
          long_description: '30% discount',
        },
      },
    },
    popular: true,
  },
  {
    id: 6,
    custom: false,
    name: 'advanced',
    api_rate_limit: 200,
    api_rate_limit_hour: 3000,
    team_members: 9,
    tags: 100,
    profiles: 1200,
    proxies: 1200,
    images: 20,
    templates: 10,
    duration: 30,
    transfer: true,
    price: 329,
    api_enabled: true,
    export_price: 50,
    is_trial: false,
    bonuses: {
      '30': { full_price: 329, bonus_price: null, bonus: null },
      '60': { full_price: 658, bonus_price: null, bonus: null },
      '90': {
        full_price: 987,
        bonus_price: 889,
        bonus: {
          type: 'DISCOUNT_3_MONTH',
          config: { name: 'DISCOUNT_3_MONTH', value: 10, days: 90 },
          short_description: '-10%',
          long_description: '10% discount',
        },
      },
      '180': {
        full_price: 1974,
        bonus_price: 1580,
        bonus: {
          type: 'DISCOUNT_6_MONTH',
          config: { name: 'DISCOUNT_6_MONTH', value: 20, days: 180 },
          short_description: '-20%',
          long_description: '20% discount',
        },
      },
      '360': {
        full_price: 3948,
        bonus_price: 2764,
        bonus: {
          type: 'DISCOUNT_12_MONTH',
          config: { name: 'DISCOUNT_12_MONTH', value: 30, days: 360 },
          short_description: '-30%',
          long_description: '30% discount',
        },
      },
    },
  },
  {
    id: 7,
    custom: true,
    name: 'custom',
    api_rate_limit: 0,
    api_rate_limit_hour: 0,
    team_members: 9,
    tags: 100,
    profiles: 1200,
    proxies: 1200,
    images: 20,
    templates: 10,
    duration: 30,
    transfer: true,
    price: 429,
    api_enabled: true,
    export_price: 50,
    is_trial: false,
    bonuses: {
      '30': { full_price: 429, bonus_price: null, bonus: null },
      '90': {
        full_price: 429 * 3,
        bonus_price: 386 * 3,
        bonus: {
          type: 'DISCOUNT_3_MONTH',
          config: { name: 'DISCOUNT_3_MONTH', value: 10, days: 90 },
          short_description: '-10%',
          long_description: '10% discount',
        },
      },
      '180': {
        full_price: 429 * 6,
        bonus_price: 343 * 6,
        bonus: {
          type: 'DISCOUNT_6_MONTH',
          config: { name: 'DISCOUNT_6_MONTH', value: 20, days: 180 },
          short_description: '-20%',
          long_description: '20% discount',
        },
      },
      '360': {
        full_price: 429 * 12,
        bonus_price: 300 * 12,
        bonus: {
          type: 'DISCOUNT_12_MONTH',
          config: { name: 'DISCOUNT_12_MONTH', value: 30, days: 360 },
          short_description: '-30%',
          long_description: '30% discount',
        },
      },
    },
  },
];

export const SUBSCRIPTION_PLANS_SALE: Array<SubscriptionType> = [
  {
    id: 2,
    custom: false,
    name: 'starter',
    api_rate_limit: 0,
    api_rate_limit_hour: 0,
    team_members: 1,
    tags: 3,
    profiles: 10,
    proxies: 1,
    images: 3,
    templates: 0,
    duration: 30,
    transfer: false,
    price: 29,
    api_enabled: false,
    export_price: 50,
    is_trial: false,
    bonuses: {
      '30': { full_price: 29, bonus_price: null, bonus: null },
      '60': { full_price: 58, bonus_price: null, bonus: null },
      '90': {
        full_price: 87,
        bonus_price: 61,
        bonus: {
          type: 'DISCOUNT_3_MONTH',
          config: { name: 'DISCOUNT_3_MONTH', value: 30, days: 90 },
          short_description: '-30%',
          long_description: '30% discount',
        },
      },
      '180': {
        full_price: 174,
        bonus_price: 105,
        bonus: {
          type: 'DISCOUNT_6_MONTH',
          config: { name: 'DISCOUNT_6_MONTH', value: 40, days: 180 },
          short_description: '-40%',
          long_description: '40% discount',
        },
      },
      '360': {
        full_price: 348,
        bonus_price: 209,
        bonus: {
          type: 'DISCOUNT_12_MONTH',
          config: { name: 'DISCOUNT_12_MONTH', value: 40, days: 360 },
          short_description: '-40%',
          long_description: '40% discount',
        },
      },
    },
  },
  {
    id: 3,
    custom: false,
    name: 'base',
    api_rate_limit: 50,
    api_rate_limit_hour: 500,
    team_members: 1,
    tags: 10,
    profiles: 100,
    proxies: 100,
    images: 5,
    templates: 2,
    duration: 30,
    transfer: true,
    price: 79,
    api_enabled: true,
    export_price: 50,
    is_trial: false,
    bonuses: {
      '30': { full_price: 79, bonus_price: null, bonus: null },
      '60': { full_price: 158, bonus_price: null, bonus: null },
      '90': {
        full_price: 237,
        bonus_price: 166,
        bonus: {
          type: 'DISCOUNT_3_MONTH',
          config: { name: 'DISCOUNT_3_MONTH', value: 30, days: 90 },
          short_description: '-30%',
          long_description: '30% discount',
        },
      },
      '180': {
        full_price: 474,
        bonus_price: 285,
        bonus: {
          type: 'DISCOUNT_6_MONTH',
          config: { name: 'DISCOUNT_6_MONTH', value: 40, days: 180 },
          short_description: '-40%',
          long_description: '40% discount',
        },
      },
      '360': {
        full_price: 948,
        bonus_price: 569,
        bonus: {
          type: 'DISCOUNT_12_MONTH',
          config: { name: 'DISCOUNT_12_MONTH', value: 40, days: 360 },
          short_description: '-40%',
          long_description: '40% discount',
        },
      },
    },
  },
  {
    id: 4,
    custom: false,
    name: 'team',
    api_rate_limit: 100,
    api_rate_limit_hour: 1500,
    team_members: 4,
    tags: 30,
    profiles: 350,
    proxies: 350,
    images: 10,
    templates: 5,
    duration: 30,
    transfer: true,
    price: 169,
    api_enabled: true,
    export_price: 50,
    is_trial: false,
    bonuses: {
      '30': { full_price: 169, bonus_price: null, bonus: null },
      '60': { full_price: 338, bonus_price: null, bonus: null },
      '90': {
        full_price: 507,
        bonus_price: 355,
        bonus: {
          type: 'DISCOUNT_3_MONTH',
          config: { name: 'DISCOUNT_3_MONTH', value: 30, days: 90 },
          short_description: '-30%',
          long_description: '30% discount',
        },
      },
      '180': {
        full_price: 1014,
        bonus_price: 609,
        bonus: {
          type: 'DISCOUNT_6_MONTH',
          config: { name: 'DISCOUNT_6_MONTH', value: 40, days: 180 },
          short_description: '-40%',
          long_description: '40% discount',
        },
      },
      '360': {
        full_price: 2028,
        bonus_price: 1217,
        bonus: {
          type: 'DISCOUNT_12_MONTH',
          config: { name: 'DISCOUNT_12_MONTH', value: 40, days: 360 },
          short_description: '-40%',
          long_description: '40% discount',
        },
      },
    },
    popular: true,
  },
  {
    id: 6,
    custom: false,
    name: 'advanced',
    api_rate_limit: 200,
    api_rate_limit_hour: 3000,
    team_members: 9,
    tags: 100,
    profiles: 1200,
    proxies: 1200,
    images: 20,
    templates: 10,
    duration: 30,
    transfer: true,
    price: 329,
    api_enabled: true,
    export_price: 50,
    is_trial: false,
    bonuses: {
      '30': { full_price: 329, bonus_price: null, bonus: null },
      '60': { full_price: 658, bonus_price: null, bonus: null },
      '90': {
        full_price: 987,
        bonus_price: 691,
        bonus: {
          type: 'DISCOUNT_3_MONTH',
          config: { name: 'DISCOUNT_3_MONTH', value: 30, days: 90 },
          short_description: '-30%',
          long_description: '30% discount',
        },
      },
      '180': {
        full_price: 1974,
        bonus_price: 1185,
        bonus: {
          type: 'DISCOUNT_6_MONTH',
          config: { name: 'DISCOUNT_6_MONTH', value: 40, days: 180 },
          short_description: '-40%',
          long_description: '40% discount',
        },
      },
      '360': {
        full_price: 3948,
        bonus_price: 2369,
        bonus: {
          type: 'DISCOUNT_12_MONTH',
          config: { name: 'DISCOUNT_12_MONTH', value: 40, days: 360 },
          short_description: '-40%',
          long_description: '40% discount',
        },
      },
    },
  },
  {
    id: 7,
    custom: true,
    name: 'custom',
    api_rate_limit: 0,
    api_rate_limit_hour: 0,
    team_members: 9,
    tags: 100,
    profiles: 1200,
    proxies: 1200,
    images: 20,
    templates: 10,
    duration: 30,
    transfer: true,
    price: 429,
    api_enabled: true,
    export_price: 50,
    is_trial: false,
    bonuses: {
      '30': { full_price: 429, bonus_price: null, bonus: null },
      '90': {
        full_price: 1287,
        bonus_price: 901,
        bonus: {
          type: 'DISCOUNT_3_MONTH',
          config: { name: 'DISCOUNT_3_MONTH', value: 30, days: 90 },
          short_description: '-30%',
          long_description: '30% discount',
        },
      },
      '180': {
        full_price: 2574,
        bonus_price: 1545,
        bonus: {
          type: 'DISCOUNT_6_MONTH',
          config: { name: 'DISCOUNT_6_MONTH', value: 40, days: 180 },
          short_description: '-40%',
          long_description: '40% discount',
        },
      },
      '360': {
        full_price: 5148,
        bonus_price: 3089,
        bonus: {
          type: 'DISCOUNT_12_MONTH',
          config: { name: 'DISCOUNT_12_MONTH', value: 40, days: 360 },
          short_description: '-40%',
          long_description: '40% discount',
        },
      },
    },
  },
];
