// extracted by mini-css-extract-plugin
export var antBtnLg = "OptionItem-module--ant-btn-lg--t-GrJ";
export var antBtnPrimary = "OptionItem-module--ant-btn-primary--rk84G";
export var antFormItem = "OptionItem-module--ant-form-item--6RWTQ";
export var antFormItemLabel = "OptionItem-module--ant-form-item-label--mqgwl";
export var antInput = "OptionItem-module--ant-input--4jPLh";
export var antMessageNotice = "OptionItem-module--ant-message-notice--XAiSN";
export var antMessageNoticeContent = "OptionItem-module--ant-message-notice-content--QY3Ot";
export var errorMessage = "OptionItem-module--errorMessage--ATg8H";
export var icon = "OptionItem-module--icon--FwSez";
export var iconDisabled = "OptionItem-module--iconDisabled--SpRk-";
export var wrapper = "OptionItem-module--wrapper--A9hD0";