import React, { useMemo, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import ContentWrap from '../ContentWrap/ContentWrap';
import { SUBSCRIPTION_PLANS_TERMS } from '../../const/subscriptions.const';
import PaymentTerms from './PaymentTerms';
import SubscriptionPlans from './SubscriptionPlans';
import * as styles from './Pricing.module.scss';

type PricingProps = {
  isNYSaleTime: boolean;
};
const Pricing = ({ isNYSaleTime }: PricingProps): React.ReactElement => {
  const [activeTerm, setActiveTerm] = useState<number>(SUBSCRIPTION_PLANS_TERMS[3]);
  const i18n = useTranslation();

  const titles = useMemo(() => {
    let subTitle = i18n.t(
      'Regardless of the size of your team, Octo Browser has a subscription that is perfect for you.'
    );

    if (isNYSaleTime) {
      subTitle = i18n.t(
        'Только с 21 по 31 декабря! При продлении или покупке новой подписки на 3, 6 и 12 месяцев — скидка до 40%! Дольше подписка — больше выгода!'
      );
    }

    return {
      title: i18n.t('Subscriptions and Prices'),
      subTitle,
    };
  }, [isNYSaleTime]);

  return (
    <div className={styles.wrapper}>
      <ContentWrap>
        <h1 className={styles.title}>{titles.title}</h1>
        <div className={styles.subTitle}>{titles.subTitle}</div>
        <PaymentTerms
          activeTerm={activeTerm}
          handleActiveTerm={setActiveTerm}
          isNYSaleTime={isNYSaleTime}
        />
        <SubscriptionPlans isNYSaleTime={isNYSaleTime} term={activeTerm} />
      </ContentWrap>
    </div>
  );
};

export default Pricing;
