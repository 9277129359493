// extracted by mini-css-extract-plugin
export var antBtnLg = "Pricing-module--ant-btn-lg--6kOwU";
export var antBtnPrimary = "Pricing-module--ant-btn-primary--1EOGU";
export var antFormItem = "Pricing-module--ant-form-item--cHPeQ";
export var antFormItemLabel = "Pricing-module--ant-form-item-label--3750i";
export var antInput = "Pricing-module--ant-input--TZN3f";
export var antMessageNotice = "Pricing-module--ant-message-notice--b3tWF";
export var antMessageNoticeContent = "Pricing-module--ant-message-notice-content--85oKW";
export var errorMessage = "Pricing-module--errorMessage--dlek2";
export var subTitle = "Pricing-module--subTitle--oZEFN";
export var title = "Pricing-module--title--iXC9T";
export var wrapper = "Pricing-module--wrapper---d1GY";