import React from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';
import Bitcoin from '../../assets/svg/bitcoin.svg';
import Ethereum from '../../assets/svg/ethereum.svg';
import Litecoin from '../../assets/svg/litecoin.svg';
import Mastercard from '../../assets/svg/mastercard.svg';
import Qiwi from '../../assets/svg/qiwi.svg';
import Tether from '../../assets/svg/tether.svg';
import Visa from '../../assets/svg/visa.svg';
import ContentWrap from '../ContentWrap/ContentWrap';
import * as styles from './PaymentMethods.module.scss';

const PAYMENTS_METHODS = [
  <Visa />,
  <Mastercard />,
  <Qiwi />,
  <Bitcoin />,
  <Ethereum />,
  <Litecoin />,
  <Tether />,
];

const PaymentMethods = (): React.ReactElement => {
  return (
    <div className={styles.wrapper}>
      <ContentWrap>
        <div className={styles.title}>
          <Trans>We accept the following payments:</Trans>
        </div>
        <div className={styles.methods}>
          {PAYMENTS_METHODS.map((method, idx) => (
            <div className={styles.method} key={idx}>
              {method}
            </div>
          ))}
        </div>
      </ContentWrap>
    </div>
  );
};

export default PaymentMethods;
