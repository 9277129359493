// extracted by mini-css-extract-plugin
export var antBtnLg = "ActivateWithButton-module--ant-btn-lg--hYfWK";
export var antBtnPrimary = "ActivateWithButton-module--ant-btn-primary--tW-qM";
export var antFormItem = "ActivateWithButton-module--ant-form-item--3cJEi";
export var antFormItemLabel = "ActivateWithButton-module--ant-form-item-label--rgQ+B";
export var antInput = "ActivateWithButton-module--ant-input--fhVGU";
export var antMessageNotice = "ActivateWithButton-module--ant-message-notice--fmvxv";
export var antMessageNoticeContent = "ActivateWithButton-module--ant-message-notice-content--3bOa6";
export var errorMessage = "ActivateWithButton-module--errorMessage--q8BF9";
export var title = "ActivateWithButton-module--title--qrp6X";
export var wrapper = "ActivateWithButton-module--wrapper--uxLao";