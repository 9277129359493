// extracted by mini-css-extract-plugin
export var antBtnLg = "PricingSaleTicker-module--ant-btn-lg--TwDiw";
export var antBtnPrimary = "PricingSaleTicker-module--ant-btn-primary--Wq9Hr";
export var antFormItem = "PricingSaleTicker-module--ant-form-item--Z7gcw";
export var antFormItemLabel = "PricingSaleTicker-module--ant-form-item-label--YQFkY";
export var antInput = "PricingSaleTicker-module--ant-input--1WEdV";
export var antMessageNotice = "PricingSaleTicker-module--ant-message-notice--JEIqO";
export var antMessageNoticeContent = "PricingSaleTicker-module--ant-message-notice-content--MI9bp";
export var circle = "PricingSaleTicker-module--circle--613FB";
export var circle1 = "PricingSaleTicker-module--circle1--YTovs";
export var circle2 = "PricingSaleTicker-module--circle2--57qHw";
export var circle3 = "PricingSaleTicker-module--circle3--wjSV0";
export var circle4 = "PricingSaleTicker-module--circle4--JX8wC";
export var errorMessage = "PricingSaleTicker-module--errorMessage--p9piE";
export var item = "PricingSaleTicker-module--item--ktZKx";
export var star = "PricingSaleTicker-module--star--nsEHz";
export var ticker = "PricingSaleTicker-module--ticker--M2F-R";
export var wrapper = "PricingSaleTicker-module--wrapper--Pmtr0";