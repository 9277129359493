// extracted by mini-css-extract-plugin
export var antBtnLg = "SubscriptionPlanPrice-module--ant-btn-lg--whX7a";
export var antBtnPrimary = "SubscriptionPlanPrice-module--ant-btn-primary--w1RZf";
export var antFormItem = "SubscriptionPlanPrice-module--ant-form-item--5ZWMm";
export var antFormItemLabel = "SubscriptionPlanPrice-module--ant-form-item-label--z09xD";
export var antInput = "SubscriptionPlanPrice-module--ant-input--vIGW5";
export var antMessageNotice = "SubscriptionPlanPrice-module--ant-message-notice--o5MkK";
export var antMessageNoticeContent = "SubscriptionPlanPrice-module--ant-message-notice-content--4dpnz";
export var currency = "SubscriptionPlanPrice-module--currency--qJrjE";
export var discount = "SubscriptionPlanPrice-module--discount--UGX-a";
export var discountValue = "SubscriptionPlanPrice-module--discountValue--RDv8I";
export var errorMessage = "SubscriptionPlanPrice-module--errorMessage--DmFts";
export var term = "SubscriptionPlanPrice-module--term--quysZ";
export var value = "SubscriptionPlanPrice-module--value--J5+qZ";
export var wrapper = "SubscriptionPlanPrice-module--wrapper--mlwel";