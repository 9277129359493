import React from 'react';
import UnCheck from '../../assets/svg/uncheck.svg';
import * as styles from './OptionItem.module.scss';
import Check from '../../assets/svg/check.svg';

type OptionItemProps = {
  text: string | React.ReactNode;
  disabled?: boolean;
};
const OptionItem = ({
  text,
  disabled,
}: OptionItemProps): React.ReactElement => {
  return (
    <li className={styles.wrapper}>
      {disabled ? (
        <UnCheck className={`${styles.icon} ${styles.iconDisabled}`} />
      ) : (
        <Check className={styles.icon} />
      )}

      <div>{text}</div>
    </li>
  );
};

export default OptionItem;
