import React from 'react';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import classNames from 'classnames';
import * as styles from './TermsItem.module.scss';

type TermItemProps = {
  term: number;
  discount: number | null;
  isActive: boolean;
  handleActive: () => void;
  isNYSaleTime: boolean;
};

const TermsItem = ({
  term,
  discount,
  isActive,
  handleActive,
  isNYSaleTime,
}: TermItemProps): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.activeItem]: isActive,
        [styles.itemNy]: isNYSaleTime,
      })}
      role="button"
      tabIndex={-1}
      onClick={handleActive}
      onKeyDown={undefined}
    >
      {term > 1 ? t('payment_term', { count: term }) : <Trans>Monthly</Trans>}
      {discount && (
        <>
          {' '}
          <span>-{discount}%</span>
        </>
      )}
    </div>
  );
};

export default TermsItem;
