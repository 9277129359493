import React from 'react';
import { SubscriptionType } from '../../types/subscriptions.types';
import { DAYS_IN_MONTH } from '../../const/subscriptions.const';

const SubscriptionPriceValue: React.FC<{
  plan: SubscriptionType;
  term: number;
}> = ({ plan, term }) => {
  const selectedTermBonuses = plan.bonuses[term];
  const bonusPrice = selectedTermBonuses.bonus_price;
  const priceValue = bonusPrice
    ? Math.ceil(bonusPrice / (term / DAYS_IN_MONTH)) ||
      selectedTermBonuses.full_price
    : plan.price;

  return <>{priceValue}</>;
};

export default SubscriptionPriceValue;
