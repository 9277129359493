import React from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';
import classNames from 'classnames';
import { SubscriptionType } from '../../types/subscriptions.types';
import * as styles from './SubscriptionPlan.module.scss';
import SubscriptionPlanPrice from './SubscriptionPlanPrice';
import SubscriptionPlanOptions from './SubscriptionPlanOptions';

type SubscriptionPlanProps = {
  plan: SubscriptionType;
  term: number;
};

const SubscriptionPlan = ({ plan, term }: SubscriptionPlanProps): React.ReactElement => {
  const bonus = plan.bonuses[term]?.bonus;

  return (
    <div className={classNames(styles.wrapper, { [styles.popular]: plan.popular })}>
      {plan.popular && (
        <div className={styles.popularMark}>
          <Trans>Popular</Trans>
        </div>
      )}
      <header className={styles.header}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>{plan.name}</div>
          {bonus && (
            <div className={styles.discount}>
              <Trans>Save</Trans> {bonus.config.value}%
            </div>
          )}
        </div>
        <SubscriptionPlanPrice plan={plan} term={term} />
      </header>
      <SubscriptionPlanOptions plan={plan} />
    </div>
  );
};

export default SubscriptionPlan;
