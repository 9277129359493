import React from 'react';
import Ticker from 'react-ticker';
import { Trans } from 'gatsby-plugin-react-i18next';
import * as styles from './PricingSaleTicker.module.scss';

const PricingSaleTicker = (): React.ReactElement => {
  const star = (
    <div className={styles.star}>
      <div className={`${styles.circle} ${styles.circle1}`} />
      <div className={`${styles.circle} ${styles.circle2}`} />
      <div className={`${styles.circle} ${styles.circle3}`} />
      <div className={`${styles.circle} ${styles.circle4}`} />
    </div>
  );
  const date = (
    <div className={styles.item}>
      <Trans>с 21 по 31 декабря</Trans>
    </div>
  );
  const discount = (
    <div className={styles.item}>
      <Trans>Выгода до 40%</Trans>
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <Ticker>
        {() => (
          <div className={styles.ticker}>
            {Array.from({ length: 10 }, (_, i) => i + 1).map((key) => (
              <React.Fragment key={key}>
                {star}
                {date}
                {star}
                {discount}
              </React.Fragment>
            ))}
          </div>
        )}
      </Ticker>
    </div>
  );
};

export default PricingSaleTicker;
