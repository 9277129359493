import React from 'react';
import Faq from '../Faq/Faq';
import { Trans } from 'gatsby-plugin-react-i18next';
import { FaqType } from '../../types/faq.types';
import { SUPPORT_ROUTE } from '../../const/routes.const';

const FAQS: Array<FaqType> = [
  {
    question: (
      <Trans>Can I transfer profiles with a Starter subscription?</Trans>
    ),
    answer: (
      <Trans>
        No. Profile transferring is available for “Base” and higher
        subscriptions.
      </Trans>
    ),
  },
  {
    question: (
      <Trans>Can I share the same Octo Browser account with a colleague?</Trans>
    ),
    answer: (
      <Trans>Yes. You can easily use 2-3 devices at the same time.</Trans>
    ),
  },
  {
    question: (
      <Trans>
        Can I pay the difference and switch, for example, from a Base
        subscription to an Advanced subscription?
      </Trans>
    ),
    answer: (
      <Trans>
        Yes. You can change your subscription to a higher subscription at any
        time by adding the difference based on the number of remaining days of
        your current subscription.
      </Trans>
    ),
  },
  {
    question: (
      <Trans>How can I get a Custom subscription with more profiles?</Trans>
    ),
    answer: (
      <Trans i18nKey={'faq.price.custom_plan'}>
        <span>Just get in touch with </span>
        <a href={SUPPORT_ROUTE} rel="nofollow">
          our support
        </a>
        <span> and our experts will help you to create a Custom plan.</span>
      </Trans>
    ),
  },
  {
    question: <Trans>How can I change my subscription?</Trans>,
    answer: (
      <Trans>
        Changing your subscription to a higher one is available at any time.
        Changing to a lower subscription is only possible after your current
        subscription expires. To do that, you would need to refill your balance
        and choose a desired subscription. Note, that if you switch to a lower
        subscription, you would need to remove extra resources such as profiles.
      </Trans>
    ),
  },
  {
    question: (
      <Trans>
        I have a Starter subscription, how can I increase the number of
        profiles?
      </Trans>
    ),
    answer: (
      <Trans>
        Purchase of additional resources is available only with a Custom
        subscription. You can increase the number of profiles if you switch from
        a Starter subscription to a Base subscription.
      </Trans>
    ),
  },
];

const FaqPricing = (): React.ReactElement => {
  return <Faq faqs={FAQS} isWhite />;
};

export default FaqPricing;
