import React from 'react';
import { SUBSCRIPTION_PLANS, SUBSCRIPTION_PLANS_SALE } from '../../const/subscriptions.const';
import { SubscriptionType } from '../../types/subscriptions.types';
import SubscriptionPlan from './SubscriptionPlan';
import * as styles from './SubscriptionPlans.module.scss';

type SubscriptionPlansProps = {
  term: number;
  isNYSaleTime: boolean;
};
const SubscriptionPlans = ({ term, isNYSaleTime }: SubscriptionPlansProps): React.ReactElement => {
  const plans = isNYSaleTime ? SUBSCRIPTION_PLANS_SALE : SUBSCRIPTION_PLANS;

  return (
    <div className={styles.wrapper}>
      {plans.map((subscriptionPlan: SubscriptionType) => (
        <SubscriptionPlan key={subscriptionPlan.id} plan={subscriptionPlan} term={term} />
      ))}
    </div>
  );
};

export default SubscriptionPlans;
