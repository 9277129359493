import React from 'react';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { SubscriptionType } from '../../types/subscriptions.types';
import { CUSTOM_PLAN_NAME, DAYS_IN_MONTH } from '../../const/subscriptions.const';
import Flex from '../common/layout/Flex';
import Box from '../common/layout/Box';
import * as styles from './SubscriptionPlanPrice.module.scss';
import SubscriptionPriceValue from './SubscriptionPriceValue';

type SubscriptionPlanPriceProps = {
  plan: SubscriptionType;
  term: number;
};
const SubscriptionPlanPrice = ({ plan, term }: SubscriptionPlanPriceProps): React.ReactElement => {
  const { t } = useI18next();
  const priceSuffix = plan.name === CUSTOM_PLAN_NAME ? '+' : '';
  const { bonus } = plan.bonuses[term];

  const period = term / DAYS_IN_MONTH;
  const termDetail: { [i: number]: string } = {
    1: t('в месяц'),
    3: t('в месяц при оплате за мес', { n: 3 }),
    6: t('в месяц при оплате за мес', { n: 6 }),
    12: t('в месяц при оплате за год'),
  };

  return (
    <>
      <div className={styles.wrapper}>
        <sup className={styles.currency}>
          <Trans>€</Trans>
        </sup>
        <div className={styles.value}>
          <SubscriptionPriceValue plan={plan} term={term} />
          {priceSuffix}
        </div>
      </div>
      <div className={styles.term}>{termDetail[period] || <Trans>в месяц</Trans>}</div>
      {bonus && (
        <Flex alignItems="center">
          <span className={styles.discountValue}>
            <Trans>€</Trans>
            {plan.price}
          </span>
          <Box p={0.5} />
          <span className={styles.discount}>
            ({bonus.config.value}% <Trans>Save</Trans>)
          </span>
        </Flex>
      )}
    </>
  );
};

export default SubscriptionPlanPrice;
